import logo from "./MapConverterLogo_v1.svg";
import "./App.css";
import { useState } from "react";

// Dummy url:
// const madummyAppleUrl =
// https://maps.apple.com/?address=Apple%20Inc.,%20One%20Apple%20Park%20Way,%20Cupertino,%20CA%2095014,%20United%20States&auid=559098170073364042&ll=37.334900,-122.009020&lsp=9902&q=Apple%20Headquarters&_ext=Cj8KBQgEEOIBCgQIBRADCgQIBhBzCgQIChAOCgQIEBABCgUILxDAAgoECFIQBQoECFUQDAoECFkQBgoFCKQBEAESJinvlj860KlCQDEBbatZ54BewDm8b8aJXqtCQEHw1q/sJ4BewFAD

// Open url with longitude and latitude: https://maps.google.com/?q=<lat>,<lng>
// Eks: https://maps.google.com/?q=59.931302,10.800462

function App() {
  const [inputVal, setInputVal] = useState("");
  const [showError, setError] = useState(false);

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const convertUrl = (val) => {
    const regex2 =
      /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/;
    const match = regex2.exec(val);
    if (match !== null) {
      setError(false);
      const googleUrl = "https://maps.google.com/?q=";
      return googleUrl + match[0];
    } else setError(true);
  };

  const handleForm = (e) => {
    let mobileUrl = false;
    let url = false;
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      mobileUrl = convertUrl(e)
      setInputVal("")
    }

    if (inputVal) {
      url = convertUrl(inputVal);
      setInputVal("");
      setError(false);
      url === undefined ? setError(true) : openWindow(url);
    } else if (mobileUrl) {
      setError(false);
      url === undefined ? setError(true) : openWindow(mobileUrl);
    } else setError(true);
  };

  const openWindow = (url) => {
    window.open(url, "_blank");
    setError(false);
  };

  async function clickEvent() {
    navigator.clipboard.readText().then(clipText => {
      setInputVal(clipText)
      handleForm(clipText)
    }
    )
  };

  return (
    <>
      <header>
        <img src="./Maps_Logo_200x200.png" className="App-logo" alt="Apple Map Converter" width="100" height="100" />
        <h1>Open Apple Maps In Google Maps</h1>
      </header>
      <main>

        {!isMobile &&
          <div>
            <button className="read-btn" onClick={() => clickEvent()}>
              Open in Google Maps
            </button>
          </div>
        }

        {isMobile &&
          <form onSubmit={handleForm}>
            <div className="inputBtnContainer">
              <input
                className="cstmInput"
                type="text"
                name="mapUrl"
                id="mapUrl"
                placeholder="Paste url, click go"
                onChange={(e) => setInputVal(e.target.value)}
                value={inputVal}
                required
              />
              <button className="basicdBtn cstmBtnGo" type="submit">
                GO
              </button>
            </div>
            {showError && (
              <p style={{ color: "#d14a4a" }}>
                Enter valid url. <br /> Example "maps.apple.com..."
              </p>
            )}
            <label
              id="inputLabel"
              htmlFor="mapUrl"
              style={{ visibility: "hidden" }}
            >
              Enter Apple Maps link. Push GO
            </label>
          </form>
        }
      </main>
      <footer>
        <p>by <a href="https://www.xcode.no" rel="noreferrer noopener" target="_blank">xCode</a></p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none"><path fill="#5000ca" fillOpacity="1" d="M0,128L30,122.7C60,117,120,107,180,106.7C240,107,300,117,360,144C420,171,480,213,540,218.7C600,224,660,192,720,181.3C780,171,840,181,900,186.7C960,192,1020,192,1080,170.7C1140,149,1200,107,1260,90.7C1320,75,1380,85,1410,90.7L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
      </footer>
    </>
  );
}

export default App;
